/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 */
@tailwind base;

/**
 * Remove the default box-shadow for invalid elements to prevent
 * inputs in Livewire components showing with a
 * red border by default in Firefox.
 *
 * See: https://github.com/laravel-frontend-presets/tall/issues/7
 */
input:invalid, textarea:invalid, select:invalid {
    box-shadow: none;
}

/**
 * This injects any component classes registered by plugins.
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 */

@layer base {
    :root {
        --color-brand: #efdf00;
        --color-button-accent: #efdf00;
        --color-button-inverse: #000000;
        --color-link: #373737;
    }
}

@layer components {
    .side-padding {
        @apply md:px-[39px] px-6;
    }

    .side-margin {
        @apply md:mx-[39px] mx-6;
    }

    .btn {
        @apply sm:text-xl flex px-6 sm:px-12 py-2 sm:py-4;
    }

    .title {
        @apply font-normal text-xl sm:text-2.5xl uppercase;
    }

    .prose-brand {
        @apply prose prose-sm sm:prose-xl;
    }

    .bg-brand-important {
        @apply bg-brand #{!important}
    }

    .margin-top {
        @apply sm:mt-brand-2 mt-0
    }

    .paginate-btn {
        @apply flex items-center justify-center border border-black h-[50px] w-[50px] hover:bg-brand
    }

    .paginate-btn-disabled {
        @apply flex items-center justify-center border border-brand-vehicle-bg h-[50px] w-[50px]
    }

    div:nth-child(even) .vehicle {
        @apply bg-brand-vehicle-bg
    }

    @media (min-width: 640px) {
        div:nth-child(4n-2) .vehicle, div:nth-child(4n-3) .vehicle {
            @apply bg-white
        }

        div:nth-child(4n) .vehicle, div:nth-child(4n-1) .vehicle {
            @apply bg-brand-vehicle-bg
        }
    }

    @media (min-width: 1210px) {
        div:nth-child(-n+4) .vehicle {
            @apply bg-white
        }

        div:nth-child(n+5) .vehicle {
            @apply bg-brand-vehicle-bg
        }
    }
}

[x-cloak] {
    display: none;
}

.theme-dacia {
    --color-brand: #646b52;
    --color-button-accent: #ffffff;
    --color-button-inverse: #ffffff;
    --color-link: #000000;
}

.editor {
    border: 1px solid #ced4da;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    color: #0D0D0D;
    background-color: #ffffff;
    overflow-scrolling: auto;

    &.error {
        border-color: #dc3545;
    }

    &.success {
        border-color: #28a745;
    }

    .content {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .ProseMirror {
        table {
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid #ced4da;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;

                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #f1f3f5;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }
        }
    }

    .tableWrapper {
        overflow-x: auto;
    }


    .ProseMirror {
        outline: none;
        margin-left: 0.25rem;

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0D0D0D, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0D0D0D, 0.1);
            margin: 2rem 0;
        }

        mark {
            background-color: #FAF594;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
        }

        pre {
            background: #0D0D0D;
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;

            code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        ul,
        ol {
            padding: 0 1rem;
        }
    }
}

